import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
//import "../../components/styles/userMangment.css";
import { Modal, Form } from "react-bootstrap";
import Cookies from "js-cookie";
import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import Alert from "@mui/material/Alert";

import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import UserContext from "../../components/Context/userContext";
import AddIcon from "@mui/icons-material/Add";
import {
  FetchingUserData,
  addUser,
  deleteUser,
  gettingUsers,
  updateUser,
} from "../../services/userManagmentPage/api";
import { isValidEmail, isValidPassword } from "../../helperFunctions/functions";

import "../../components/styles/index.css";
import { jwtDecode } from "jwt-decode";

const initialStateInfo = {
  username: "",
  password: "",
  role: "",
  phonenumber: "",
};

function Users() {
  const { setUserRole } = React.useContext(UserContext);

  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isadmin, setisAdmin] = useState(false);

  const [setModalEdit, setShowModalEdit] = useState(false);
  const [showModaldel, setShowModalDel] = useState(false);
  const [userId, setuserId] = useState(null);
  const [edituser, setedituserId] = useState(null);
  const [info, setInfo] = useState(initialStateInfo);
  const [userData, setUserData] = useState({
    username: "",
    password: "",
    role: "",
    email: "",
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (edituser) {
        try {
          const data = await FetchingUserData(edituser);
          setUserData(data);
        } catch (error) {
          if (error.message === "Token is invalid or expired") {
            Cookies.remove("ElectionToken");
            setUserRole(null);
          } else {
            toast.error(error.message);
          }
        }
      }
    };

    fetchUserData();
  }, [edituser]);
  const handleFormChange = (event) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  const handleEditSubmit = async () => {
    if (
      userData.email === "" ||
      userData.username === "" ||
      userData.role === ""
    ) {
      toast.error("Please fill all fields");
      return;
    }
    if (!isValidPassword(userData.password)) {
      toast.error(
        "Password must be 8+ characters with uppercase, lowercase, special character, and a number ",
        { theme: "dark" }
      );
      return;
    }
    if (!isValidEmail(userData.email)) {
      toast.error("Enter a valid email address");
      return;
    }
    try {
      const success = await updateUser(edituser, userData);

      if (success) {
        toast.success("User updated successfully");
        RenderUsers();

        setShowModalEdit(false);
      }
    } catch (error) {
      if (error.message === "Token is invalid or expired") {
        Cookies.remove("ElectionToken");
        setUserRole(null);
      } else {
        toast.error(error.message);
      }
    }
  };
  const navigate = useNavigate();
  const [AddShowModal, setAddShowModal] = useState(false);
  const handleAddShowModal = () => setAddShowModal(true);
  const handleAddCloseModal = () => setAddShowModal(false);
  const userRef = useRef(null);
  const passwordRef = useRef(null);
  const roleRef = useRef(null);
  const EmailRef = useRef(null);

  const ChangeInfo = useCallback((e) => {
    setInfo((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
  }, []);

  const ValidateUser = useCallback(
    async (e) => {
      e.preventDefault();
      const username = userRef.current.value;
      const password = passwordRef.current.value;
      const role = roleRef.current.value;
      const email = EmailRef.current.value;

      if (
        username.length === 0 ||
        password.length === 0 ||
        role.length === 0 ||
        email.length === 0
      ) {
        toast.error("Please fill all fields");
      } else {
        if (!isValidPassword(password)) {
          toast.error(
            "Password must be 8+ characters with uppercase, lowercase, special character, and a number ",
            { theme: "dark" }
          );
        } else if (!isValidEmail(email)) {
          toast.error("Please enter a valid email address");
        } else {
          try {
            await addUser(info);

            toast.success("User added successfully");
            handleAddCloseModal();
            RenderUsers();
          } catch (error) {
            if (error.message === "Token is invalid or expired") {
              Cookies.remove("ElectionToken");
              setUserRole(null);
            } else {
              toast.error(error.message);
            }
          }
        }
      }
    },
    [info, navigate]
  );
  const handleRowClick = (index) => {
    setSelectedRow(index);
  };

  const RenderUsers = async () => {
    try {
      const usersData = await gettingUsers();
      setData(usersData);
    } catch (error) {
      if (error.message === "Token is invalid or expired") {
        setUserRole(null);
      } else {
        console.log("Error fetching the data:", error.message);
        toast.error("Error fetching user data");
      }
    }
  };
  const handleRemove = (id) => {
    setShowModalDel(true);
    setuserId(id);
  };
  const handleEdit = (id) => {
    setShowModalEdit(true);
    setedituserId(id);
  };

  useEffect(() => {
    if (jwtDecode(Cookies.get("ElectionToken")).role === "Admin") {
      setisAdmin(true);
      RenderUsers();
    } else {
      setisAdmin(false);
    }
  }, []);

  const [Datapage, setDataPage] = useState(1);
  const DatapageSize = 10;
  const datastart = (Datapage - 1) * DatapageSize;
  const dataend = datastart + DatapageSize;
  const currentPagedata = data.slice(datastart, dataend);
  const datatotalPages = Math.ceil(data.length / DatapageSize);
  function handlePervData() {
    setDataPage((prevPage) => Math.max(prevPage - 1, 1));
  }
  function handleNextData() {
    setDataPage((prevPage) => Math.min(prevPage + 1, datatotalPages));
  }

  const deleteusers = async () => {
    try {
      const message = await deleteUser(userId);
      toast.success(message);
      setShowModalDel(false);
      RenderUsers();
    } catch (error) {
      if (error.message === "Token is invalid or expired") {
        Cookies.remove("ElectionToken");
        setUserRole(null);
      } else {
        toast.error(error.message || "Error while removing this user");
      }
    }
  };
  // # prevent default of submitting form
  const submitUserData = (e) => {
    e.preventDefault();
  };
  return (
    <div className="pb-14">
      {isadmin ? (
        <div id="Home">
          <div className="container-fluid py-5">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <Modal show={AddShowModal} onHide={handleAddCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={submitUserData}>
                      <div className="form-group text-start mb-3">
                        <label htmlFor="Username" className="form-label">
                          User Name
                        </label>
                        <input
                          type="text"
                          ref={userRef}
                          name="username"
                          id="Username"
                          className="form-control"
                          placeholder="Full Name"
                          onChange={ChangeInfo}
                        />
                      </div>

                      <div className="form-group text-start mb-3">
                        <label htmlFor="Password" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          ref={passwordRef}
                          name="password"
                          id="Password"
                          className="form-control"
                          placeholder="Password"
                          onChange={ChangeInfo}
                        />
                      </div>

                      <div className="form-group text-start mb-3">
                        <label htmlFor="Email" className="form-label">
                          Email Address
                        </label>
                        <input
                          type="email"
                          ref={EmailRef}
                          name="email"
                          id="Email"
                          className="form-control"
                          placeholder="Email"
                          onChange={ChangeInfo}
                        />
                      </div>

                      <div className="form-group text-start mb-3">
                        <label htmlFor="Role" className="form-label">
                          User Role
                        </label>
                        <select
                          ref={roleRef}
                          name="role"
                          id="Role"
                          className="form-control"
                          onChange={ChangeInfo}
                        >
                          <option value="">Select a role</option>
                          <option value="Admin">Admin</option>

                          <option
                            value="Data_Analyst"
                            className="text-bg-custom-green-700"
                          >
                            Data Analyst
                          </option>
                          <option value="Field_Organizer">
                            Field Organizer
                          </option>
                          <option value="Campaign_Manager">
                            Campaign Manager
                          </option>
                          <option value="Demo">Demo</option>
                        </select>
                      </div>
                    </form>
                  </Modal.Body>

                  <Modal.Footer>
                    <button
                      className="inline-block w-full rounded bg-custom-green-500 px-7 pb-2 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:bg-custom-green-600 hover:shadow-lg focus:bg-custom-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-custom-green-700 active:shadow-lg dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                      onClick={(e) => {
                        ValidateUser(e);
                      }}
                    >
                      <span className="">Add User</span>
                    </button>
                  </Modal.Footer>
                </Modal>
                <Modal
                  show={setModalEdit}
                  onHide={() => setShowModalEdit(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group>
                        <Form.Label className="contextMenuLabel">
                          Username
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="username"
                          className="modal-input mb-3"
                          value={userData.username}
                          onChange={handleFormChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="contextMenuLabel">
                          Password
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="password"
                          className="modal-input mb-3 "
                          value={userData.password}
                          onChange={handleFormChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="contextMenuLabel">
                          Email
                        </Form.Label>
                        <Form.Control
                          type="tel"
                          name="email"
                          className="modal-input mb-3"
                          value={userData.email}
                          onChange={handleFormChange}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Label className="contextMenuLabel">
                          Role
                        </Form.Label>
                        <Form.Control
                          as="select"
                          name="role"
                          value={userData.role}
                          onChange={handleFormChange}
                          className="modal-input mb-3"
                        >
                          <option disabled value="">
                            Select a role
                          </option>
                          <option value="Admin">Admin</option>

                          <option value="Data_Analyst">Data Analyst</option>
                          <option value="Field_Organizer">
                            Field Organizer
                          </option>
                          <option value="Campaign_Manager">
                            Campaign Manager
                          </option>
                          <option value="Demo">Demo</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="inline-block w-full rounded bg-custom-green-500 px-7 pb-2 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-md transition duration-150 ease-in-out hover:bg-custom-green-600 hover:shadow-lg focus:bg-custom-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-custom-green-700 active:shadow-lg dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
                      onClick={handleEditSubmit}
                    >
                      Save Change
                    </button>
                  </Modal.Footer>
                </Modal>

                <Modal
                  show={showModaldel}
                  onHide={() => setShowModalDel(false)}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    Are you sure you want to remove this user?
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      onClick={deleteusers}
                      className="btn btn-outline-danger ms-3"
                      style={{
                        width: "150px",
                        height: "50px",
                        borderRadius: "20px",
                        font: "normal normal 600 16px/19px Proxima Nova",
                        color: " #D32F2F",
                      }}
                    >
                      Delete
                    </button>
                  </Modal.Footer>
                </Modal>

                <div className="flex justify-between items-center mb-10">
                  <div className="text-slate-700 font-semibold text-2xl">
                    Vantiqa Users
                  </div>

                  <button
                    className="inline-flex items-center w-auto h-auto px-4 py-2 bg-custom-green-500 text-white font-medium uppercase text-sm leading-normal rounded-lg transition duration-150 ease-in-out hover:bg-custom-green-600 hover:shadow-lg focus:outline-none focus:bg-custom-green-600 focus:shadow-lg active:bg-custom-green-700 shadow-md dark:shadow-none"
                    id="btn-add"
                    onClick={handleAddShowModal}
                  >
                    <AddIcon className="mr-2" />{" "}
                    {/* Assuming AddIcon takes className for sizing */}
                    Add New User
                  </button>
                </div>
                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left text-gray-500 table table-hover">
                    <thead className="text-gray-700 uppercase">
                      <tr>
                        <th className="px-4 py-3 border-b-2 border-gray-200 bg-gray-100 font-bold text-gray-600 text-left">
                          {" "}
                          #{" "}
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 font-bold text-gray-600">
                          {" "}
                          Name{" "}
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 font-bold text-gray-600">
                          {" "}
                          Password{" "}
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 font-bold text-gray-600">
                          {" "}
                          Role{" "}
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 font-bold text-gray-600">
                          {" "}
                          Email{" "}
                        </th>
                        <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 font-bold text-gray-600 text-right">
                          {" "}
                          Action{" "}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.length > 0 ? (
                        currentPagedata.map((place, i) => (
                          <tr
                            key={place.id}
                            className={`bg-white border-b ${
                              selectedRow === i
                                ? "bg-gray-100 dark:bg-gray-700"
                                : ""
                            }`}
                            onClick={() => handleRowClick(i)}
                          >
                            <td className="px-4 py-4 font-medium text-gray-900 text-left">
                              {i + 1}
                            </td>
                            <td className="px-6 py-4 text-md  text-gray-900">
                              {place.username || "__"}
                            </td>
                            <td className="px-6 py-4 font-medium text-gray-700">
                              {place.password || "__"}
                            </td>
                            <td className="px-6 py-4 font-medium text-gray-900">
                              <span
                                className={`font-semibold ${
                                  place.role === "Data_Analyst"
                                    ? "text-custom-green-500 bg-green-100 rounded p-1"
                                    : place.role === "Admin"
                                    ? "text-red-500 bg-rose-100 rounded "
                                    : "text-gray-900"
                                }`}
                              >
                                {place.role || "__"}
                              </span>
                            </td>
                            <td className="px-6 py-4 font-medium text-lg text-gray-900">
                              {place.email || "__"}
                            </td>
                            <td className="px-6 py-4 font-medium text-gray-900 text-right  justify-end space-x-3">
                              <EditIcon
                                className="text-gray-400 hover:text-black cursor-pointer"
                                onClick={() => handleEdit(place.id)}
                              />
                              <DeleteIcon
                                className="text-gray-400 hover:text-black cursor-pointer"
                                onClick={() => handleRemove(place.id)}
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan="6"
                            className="text-center py-4 text-red-900"
                          >
                            No users found
                          </td>
                        </tr>
                      )}
                      {data.length > 0 && (
                        <tr className="bg-white dark:bg-gray-800">
                          <td colSpan="6" className="px-6 py-4 text-right">
                            <div className="flex justify-end items-center">
                              <FaCaretLeft
                                className={`${
                                  Datapage === 1
                                    ? "text-gray-200 bg-custom-green-500 cursor-not-allowed"
                                    : "text-teal-500 cursor-pointer bg-transparent"
                                } h-10 w-10 p-2 rounded-lg`}
                                onClick={Datapage === 1 ? null : handlePervData}
                                style={{ fontSize: "1.5rem" }}
                              />
                              {[...Array(datatotalPages).keys()].map(
                                (pageNumber) => (
                                  <button
                                    key={pageNumber}
                                    className={`px-3 mx-1 py-1  h-10 rounded-lg text-lg ${
                                      Datapage === pageNumber + 1
                                        ? "bg-custom-green-500 text-white"
                                        : "bg-gray-200 text-custom-green-500"
                                    }`}
                                    onClick={() => setDataPage(pageNumber + 1)}
                                  >
                                    {pageNumber + 1}
                                  </button>
                                )
                              )}
                              <FaCaretRight
                                className={`${
                                  Datapage === datatotalPages
                                    ? "text-gray-200 bg-custom-green-500 cursor-not-allowed"
                                    : "text-teal-500 cursor-pointer bg-transparent"
                                } h-10 w-10 p-2 rounded-lg`}
                                onClick={
                                  Datapage === datatotalPages
                                    ? null
                                    : handleNextData
                                }
                                style={{ fontSize: "1.3rem" }}
                              />
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container" style={{ marginTop: "8rem " }}>
          {" "}
          <Alert severity="error">
            You dont have access to this page, Please contact the Admin
          </Alert>
        </div>
      )}
    </div>
  );
}

export default Users;
