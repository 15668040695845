import React, { useState, useRef, useContext } from "react";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode"; // Corrected import
import UserContext from "../Context/userContext";
import { otpCheck } from "../../services/loginApi/api";
import { useNavigate } from "react-router-dom";
//import '/app/src/index.css';
import "../styles/index.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const OTPForm = ({ username }) => {
  const { setUserRole } = useContext(UserContext);
  const [otpValues, setOtpValues] = useState(new Array(6).fill(""));
  const inputRefs = useRef(new Array(6).fill(null));
  const navigate = useNavigate(); // Using useHistory for navigation

  const handleChange = (index, value) => {
    const newOtpValues = [...otpValues];
    if (/^\d$/.test(value)) {
      newOtpValues[index] = value;
      setOtpValues(newOtpValues);
      if (index < otpValues.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleVerify = async () => {
    const oneTimePassword = otpValues.join("");
    const info = {
      otp: oneTimePassword,
      username: username,
    };
    try {
      const data = await otpCheck(info);
      if (data.token) {
        Cookies.set("ElectionToken", data.token);
        const decoded = jwtDecode(data.token);
        setUserRole(decoded.role);
        toast.success(
          `Welcome Mr. ${
            decoded.username.charAt(0).toUpperCase() +
            decoded.username.slice(1).toLowerCase()
          }`
        );
        navigate("/"); // Navigate to the home page after success
      }
    } catch (error) {
      toast.error(error.toString());
    }
  };

  const handleBack = () => {
    history.goBack(); // Use goBack for navigation back
  };

  return (
    <div className="Otp-Page pb-14">
      <div className="">
        <div className="shrink-0  w-3/4  text-left">
          <div className="text-slate-700 text-2xl font-semibold ml-10 leading-7">
            We Sent you an OTP to Your Email Address, Please check it
          </div>
        </div>
        <div className="flex justify-center">
          {otpValues.map((value, index) => (
            <input
              key={index}
              type="text"
              ref={(el) => (inputRefs.current[index] = el)}
              maxLength="1"
              value={value}
              onChange={(e) => handleChange(index, e.target.value)}
              className="w-20 h-16 mx-1 my-5 bg-white rounded-xl border border-slate-200 font-semibold text-xl text-center"
            />
          ))}
        </div>
        <button
          className="inline-block w-9/12 rounded-lg bg-custom-green-500 px-7 pb-2 pt-3 text-sm font-medium leading-normal text-white shadow-sm transition duration-150 ease-in-out hover:bg-custom-green-600 hover:shadow-lg focus:bg-custom-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-custom-green-600 active:shadow-lg"
          onClick={handleVerify}
        >
          <div className="text-lg font-semibold">Verify</div>
        </button>
        <button
          className="flex pt-4 items-center justify-center px-4 py-2 bg-white text-gray-500 font-medium rounded  focus:outline-none"
          onClick={handleBack}
        >
          <ArrowBackIcon className="mr-2" />
          Go Back
        </button>
      </div>
    </div>
  );
};

export default OTPForm;
