// import UploadFileModal from "./UploadFileModal";
import SendMessageInput from "./send-message-input";
import { RefetchOptions, QueryObserverResult } from "@tanstack/react-query";
// import { useState } from "react";
// import { QuestionModal } from "./QuestionsModel";
// import { type FileState } from "@/components/MutlipleFileUpload";
type SendMessageProps = {
  scrollToBottom: () => void;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  messages: Message[];
  session: null;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<any, Error>>;
  data: responseType | undefined;
  botLoading: boolean;
  setBotLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setQuestionAnsweredLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetchChats: any;
  isFileUploadedBefore: boolean;
};

const SendMessage = ({
  scrollToBottom,
  setMessages,
  session,
  messages,
  refetch,
  data,
  botLoading,
  setBotLoading,
  refetchChats,
}: SendMessageProps) => {
  return (
    <div className=" w-full py-6 flex flex-col items-center gap-y-2 ">
      {data?.data && (
        <div className=" self-end text-muted-foreground text-xs pr-4">
          Price : {data?.data?.price?.toFixed(3)}$
        </div>
      )}
      <div className=" w-full flex items-center gap-x-6">
        <SendMessageInput
          scrollToBottom={scrollToBottom}
          setMessages={setMessages}
          session={session}
          botLoading={botLoading}
          messages={messages}
          refetch={refetch}
          data={data}
          setBotLoading={setBotLoading}
          refetchChats={refetchChats}
        />
      </div>
    </div>
  );
};

export default SendMessage;
