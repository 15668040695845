import { create } from 'zustand'

type ControlSideBar = {
    isOpen: boolean
    setIsOpen: (isOpen: boolean) => void
}

export const useControlSideBar = create<ControlSideBar>((set) => ({
    isOpen: false,
    setIsOpen: (isOpen) => set({ isOpen })
}))

