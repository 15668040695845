import vantiqaLogo from "../../images/Vantiqa logos-02@2x.png";
import { Link as ReactLink } from "react-router-dom";
import UserContext from "../Context/userContext";
import React, { useEffect, useState, useContext } from "react";
import "../styles/navbar.css";
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonPinOutlinedIcon from "@mui/icons-material/PersonPinOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import Cookies from "js-cookie";
import "../styles/index.css";
import Sidebar from "../../app/(chat)/_components/side-menu/index";
import { useFetchChats } from "../../store/useFetchChat";

import { useNavigate, useLocation } from "react-router-dom";

function NavBar() {
  const [show, setShow] = useState(false);
  const { userRole, setUserRole } = useContext(UserContext);
  const navigate = useNavigate();
  const { data, isLoading, session, refetch } = useFetchChats();

  const logout = () => {
    Cookies.remove("ElectionToken");
    setUserRole(null);
    navigate("/login");
  };

  const redirectToSearchPage = () => {
    navigate("/");
  };

  const [activeLink, setActiveLink] = useState("");
  const location = useLocation();

  useEffect(() => {
    setShow(
      location.pathname !== "/login" && !location.pathname.includes("/chat")
    );
    setActiveLink(location.pathname);
  }, [location.pathname]);

  return (
    show && (
      <nav className="flex items-center justify-between p-0 bg-black text-white px-2">
        <div className=" flex items-center gap-x-2 ml-6">
          <div
            className="flex items-center cursor-pointer"
            onClick={redirectToSearchPage}
          >
            <img src={vantiqaLogo} alt="Vantiqa Logo" className="h-6 px-3" />
          </div>
        </div>
        <div className="flex space-x-8">
          <ReactLink
            to="/"
            className={`mx-6 py-2 px-1 rounded text-white no-underline ${
              activeLink === "/" ? "bg-gray-600" : "hover:bg-gray-700"
            }`}
          >
            <PersonPinOutlinedIcon className="mr-2" /> Individuals
          </ReactLink>

          <ReactLink
            to="/users"
            className={`mx-6 py-2 px-1 rounded text-white no-underline ${
              activeLink === "/users" ? "bg-gray-600" : "hover:bg-gray-700"
            }`}
          >
            <PeopleOutlinedIcon className="mr-2" /> Users
          </ReactLink>
          <ReactLink
            to="/chat"
            className={`mx-6 py-2 px-1 rounded text-white no-underline ${
              activeLink === "/chat" ? "bg-gray-600" : "hover:bg-gray-700"
            }`}
          >
            <DashboardIcon className="mr-2" /> Chat
          </ReactLink>
          <button
            onClick={logout}
            className="px-3 py-2 rounded text-red-500 hover:bg-gray-700"
          >
            <ExitToAppIcon className="mr-2" /> Logout
          </button>
        </div>
      </nav>
    )
  );
}
export default NavBar;
