import { create } from "zustand";

type ChatFilesState = {
  pdfFiles: string[];
  csvFiles: string[];
  addPdfFiles: (pdfFiles: string[]) => void;
  addCsvFiles: (csvFiles: string[]) => void;
};

export const useChatFiles = create<ChatFilesState>((set) => ({
  pdfFiles: [],
  csvFiles: [],
  addPdfFiles: (pdfFiles) => set((state) => ({ pdfFiles: pdfFiles })),
  addCsvFiles: (csvFiles) => set((state) => ({ csvFiles: csvFiles })),
}));
