import { create } from "zustand";
import Cookie from "js-cookie";
type Auth = {
  token: string;
  setToken: (token: string) => void;
  removeToken: () => void;
};

const getStoredToken = () => {
  const token = Cookie.get("ElectionToken") || "";
  return token;
};

export const useAuth = create<Auth>((set) => ({
  token: getStoredToken(),
  setToken: (token) => {
    Cookie.set("ElectionToken", token);
    set({ token });
  },
  removeToken: () => {
    Cookie.remove("ElectionToken");
    set({ token: "" });
  },
}));
