import React from "react";
import Logo from "@/public/logo.png";

const Loading = () => {
  return (
    <>
      <div className=" z-[999999] w-full h-full inset-0 bg-black/50 absolute flex flex-col gap-y-5 items-center justify-center animate-pulse">
        <img src={Logo} alt="logo" width={240} height={200} />
        <span className=" font-medium text-2xl">
          Please hold on as we analyze your data…
        </span>
      </div>
    </>
  );
};

export default Loading;
