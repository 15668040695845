import { create } from "zustand";
import Cookies from "js-cookie";

type userData = {
  token: string;
  setToken: (token: string) => void;
  removeToken: () => void;
};

const getTokenFromCookie = () => {
  const token = Cookies.get("token");
  return token;
};

export const useUserData = create<userData>((set) => ({
  token: getTokenFromCookie(),
  setToken: (token) => {
    set({ token });
    Cookies.set("token", token);
  },
  removeToken: () => {
    set({ token: "" });
    Cookies.remove("token");
  },
}));
