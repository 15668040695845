import ChatCard from "../chat-card";
import SendMessage from "../send-message/send-message";
import { useParams, useLocation } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import { GetChatMessages, GetChats } from "@/data/chat";
import { useQuery } from "@tanstack/react-query";
import { toast } from "sonner";
import Loading from "@/components/Loading";
import { jwtDecode } from "jwt-decode";
import { useCharts } from "@/store/useCharts";
// import SidePanel from "../side-panel";
import { useChatFiles } from "@/store/useChatFiles";
import { useFetchChats } from "@/store/useFetchChat";
import Cookies from "js-cookie";
import NavBar from "../navbar-chat";

type ChatPageProps = {
  session: null;
};

type responseType = {
  data: {
    chat_history: Message[];
    file_uploaded: boolean;
    price: number;
  };
};

const ChatPage = ({ session }: ChatPageProps) => {
  const { chatId } = useParams<{ chatId: string }>();
  const messageRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<Message[]>([]);
  const [userId, setUserId] = useState<number>(0);
  const [key, setKey] = useState<number>(0);
  const [botLoading, setBotLoading] = useState<boolean>(false);
  const { setData, setIsLoading, setRefetch, setSession } = useFetchChats();
  const [QuestionAnsweredLoading, setQuestionAnsweredLoading] =
    useState<boolean>(false);
  const [isFileUploadedBefore, setIsFileUploadedBefore] =
    useState<boolean>(false);

  useEffect(() => {
    setKey((prev) => prev + 1);
  }, []);

  const { addCsvFiles, addPdfFiles } = useChatFiles();
  const { charts } = useCharts();
  const location = useLocation();

  const { data, isLoading, error, refetch } = useQuery({
    queryKey: ["chatMessages", chatId],
    queryFn: async () => {
      const response = (await GetChatMessages({
        chat_id: Number(chatId),
      })) as responseType;
      console.log(response);
      setIsFileUploadedBefore(response.data.file_uploaded);
      setMessages(response.data.chat_history);
      return response;
    },
    enabled: !!chatId,
  });

  useEffect(() => {
    if (location.pathname === "/chat") {
      console.log("Resetting files");
      addCsvFiles([]);
      addPdfFiles([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    setKey((prev) => prev + 1);
    const token = Cookies.get("ElectionToken");
    if (token) {
      const decoded = jwtDecode(token) as { id: number };
      setUserId(decoded.id);
    }
  }, []);

  const {
    data: chatData,
    isLoading: ChatLoading,
    refetch: chatsRefetch,
  } = useQuery({
    queryKey: ["chats", userId],
    queryFn: async () => {
      const response = await GetChats({ user_id: userId });
      return response;
    },
    enabled: !!userId,
  });
  useEffect(() => {
    if (chatData) {
      setData(chatData);
    }
    if (ChatLoading) {
      setIsLoading(ChatLoading);
    }
    if (chatsRefetch) {
      setRefetch(chatsRefetch);
    }
    if (session) {
      setSession(session);
    }
  }, [chatData, ChatLoading, chatsRefetch, session]);
  const scrollToBottom = () => {
    messageRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    messageRef.current?.scrollIntoView();
  }, [messages]);

  if (charts) console.log("charts", charts);
  if (error && chatId) toast.error("Error getting chat messages");

  if (QuestionAnsweredLoading) return <Loading />;

  return (
    <div key={key} className="relative w-full h-screen  flex flex-col">
      <NavBar />
      <div className="flex-grow overflow-y-auto flex-1 scroll-smooth px-8 md:px-20o">
        <ChatCard
          messageRef={messageRef}
          messages={messages || []}
          data={data}
          isLoading={isLoading}
          botLoading={botLoading}
        />
      </div>
      <div className=" flex shrink-0 px-8 md:px-20">
        <SendMessage
          session={session}
          scrollToBottom={scrollToBottom}
          setMessages={setMessages}
          messages={messages}
          refetch={refetch}
          data={data}
          botLoading={botLoading}
          setBotLoading={setBotLoading}
          setQuestionAnsweredLoading={setQuestionAnsweredLoading}
          refetchChats={chatsRefetch}
          isFileUploadedBefore={isFileUploadedBefore}
        />
      </div>
    </div>
  );
};

export default ChatPage;
