import { useState } from "react";

import { Button } from "@/components/ui/button";
import { ExternalLinkIcon, Loader2 } from "lucide-react";
import TableModal from "./table-modal";
import csvImage from "@/public/csv-file.png";

type CSVTableProps = {
  csvLink: string;
  csvFileName: string;
};

const CSVTable = ({ csvLink, csvFileName }: CSVTableProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <div className="flex flex-col max-h-[800px]">
      <div className="flex items-center gap-x-2 text-[#4E4E4E] bg-white border-t border-[#EEEEEE] border-x p-3 rounded-t-lg">
        <img src={csvImage} alt="CSV file" className="w-8 h-8" />
        <h2 className="text-xl font-bold">{csvFileName}</h2>
        <Button
          size="icon"
          variant="ghost"
          className="ml-3 flex items-center gap-2"
          onClick={handleToggleModal}
        >
          <ExternalLinkIcon size={16} />
          {loading && <Loader2 className="animate-spin" size={16} />}{" "}
          {/* Loader */}
        </Button>
      </div>

      {isModalOpen && (
        <TableModal
          isOpen={isModalOpen}
          handleToggleModal={handleToggleModal}
          csvLink={csvLink}
          setLoading={setLoading} // Pass setLoading to TableModal
        />
      )}
    </div>
  );
};

export default CSVTable;
