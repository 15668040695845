import { cn } from "@/lib/utils";
import { TrashIcon, Pencil1Icon, CheckIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { RenameChat, DeleteChat } from "@/data/chat";
import { toast } from "sonner";
import { Link, useNavigate } from "react-router-dom";

type SideMenuItemProps = {
  chat: {
    id: number;
    name: string;
  };
  chatId: number | undefined;
  chats: any;
  refetch: any;
};

const SideMenuItem = ({ chat, chatId, chats, refetch }: SideMenuItemProps) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [newName, setNewName] = useState<string>(chat.name);
  const navigate = useNavigate();

  const UpdateChatName = useMutation({
    mutationKey: ["renameChat"],
    mutationFn: async () => {
      const response = await RenameChat({
        chat_id: chat.id,
        chatName: newName,
      });
      refetch();
      return response;
    },
    onSuccess: () => {
      toast.success("Chat name updated successfully");
    },
    onError: () => {
      toast.error("Error updating chat name");
    },
  });

  const DeleteChatMutation = useMutation({
    mutationKey: ["deleteChat"],
    mutationFn: async () => {
      const response = await DeleteChat({ chat_id: chat.id });
      return response;
    },
    onSuccess: () => {
      chats = chats.filter((item: any) => item.id !== chat.id);
      refetch();
      if (chatId === chat.id) navigate(`/chat`);
      toast.success("Chat deleted successfully");
    },
    onError: () => {
      toast.error("Error deleting chat");
    },
  });

  const handelEdit = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    setIsEdit(!isEdit);
  };

  const handleRename = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    handelEdit(e);
    if (newName === chat.name) return;
    UpdateChatName.mutateAsync();
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    DeleteChatMutation.mutateAsync();
  };

  const handleLinkClick = (e: React.MouseEvent) => {
    if (
      e.target instanceof SVGElement ||
      e.target instanceof HTMLButtonElement
    ) {
      e.preventDefault();
    }
  };

  return (
    <div
      className={cn(
        "text-sm flex items-center gap-x-2 w-full p-4 py-5 cursor-pointer border rounded-[10px]",
        chatId === chat.id ? "border-brand-primary" : "border-[#4B4C4E]"
      )}
    >
      <div key={chat.id} className="flex-1 flex flex-col gap-y-2">
        <div className=" w-full">
          {isEdit ? (
            <input
              type="text"
              className={cn(
                "w-full rounded-lg p-2 bg-transparent focus:outline-none",
                isEdit ? "border border-brand-primary" : "border-none"
              )}
              defaultValue={chat.name}
              disabled={!isEdit}
              onChange={(e) => setNewName(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleRename(e);
                }
              }}
            />
          ) : (
            <Link to={`/chat/${chat.id}`} className="text-white w-full">
              <div className=" max-w-full w-full">{chat.name}</div>
            </Link>
          )}
        </div>
      </div>
      <div className=" flex flex-row items-center gap-x-2">
        {isEdit ? (
          <CheckIcon
            className="w-4 h-4 text-brand-primary cursor-pointer"
            onClick={handleRename}
          />
        ) : (
          <Pencil1Icon
            className="w-4 h-4 text-white cursor-pointer"
            onClick={handelEdit}
          />
        )}
        <TrashIcon
          className="w-4 h-4 text-white cursor-pointer"
          onClick={handleDelete}
        />
      </div>
    </div>
  );
};

export default SideMenuItem;
