import ChatPage from "@/app/(chat)/_components/ChatPage";
import "../../../../globals.css";
const ConversationPage = () => {
  return (
    <div className=" flex flex-col h-full w-full bg-[#F9F9FB]">
      <div className=" w-full h-full flex items-center justify-center">
        <ChatPage session={null} />
      </div>
    </div>
  );
};

export default ConversationPage;
