import { Modal, ModalContent, ModalBody } from "@nextui-org/modal";
import { parse } from "csv-parse/browser/esm";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { ChevronsDownIcon } from "lucide-react";
import axios from "axios";

type CSVData = {
  [key: string]: string;
};

type CSVTableProps = {
  csvLink: string;
  handleToggleModal: () => void;
  isOpen: boolean;
  setLoading: (loading: boolean) => void; // Add setLoading prop
};

const TableModal = ({
  csvLink,
  handleToggleModal,
  isOpen,
  setLoading,
}: CSVTableProps) => {
  const [csvData, setCsvData] = useState<CSVData[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);

  useEffect(() => {
    const fetchCSVData = async () => {
      setLoading(true); // Start loader
      try {
        const response = await axios.get(csvLink);
        const csvContent = response.data;

        parse(
          csvContent,
          {
            columns: true, // Automatically generate an array of objects with keys
            trim: true, // Trim whitespace around columns
          },
          (err, output: CSVData[]) => {
            if (err) {
              console.error("Error parsing CSV data:", err);
            } else {
              if (output.length > 0) {
                setHeaders(Object.keys(output[0]));
              }
              setCsvData(output);
            }
          }
        );
      } catch (error) {
        console.error("Error fetching CSV data:", error);
      } finally {
        setLoading(false); // Stop loader
      }
    };

    fetchCSVData();
  }, [csvLink, setLoading]);

  // If there is no data in csvData, do not render anything
  if (!csvData.length) {
    return null;
  }
  return (
    <Modal
      className="w-screen h-screen overflow-y-auto"
      size="full"
      isOpen={isOpen}
      onOpenChange={handleToggleModal}
    >
      <ModalContent className="w-full h-full overflow-y-auto">
        <ModalBody className="w-full h-full overflow-y-auto">
          <div className="flex flex-col max-h-[1080px] overflow-y-auto">
            <div className="flex justify-between items-center text-[#4E4E4E] bg-white border-t border-[#EEEEEE] border-x p-3 rounded-t-lg">
              <h2 className="text-xl font-bold">response.csv</h2>
              <Button size="icon" variant="ghost" onClick={handleToggleModal}>
                <ChevronsDownIcon size={20} />
              </Button>
            </div>

            <Table className="text-[#4E4E4E] bg-white border border-[#EEEEEE] mt-0">
              <TableHeader className="bg-[#F9F9F9] text-[#4E4E4E] border border-[#EEEEEE]">
                <TableRow>
                  <TableHead className="bg-[#F9F9F9] text-center px-5">
                    #
                  </TableHead>
                  {headers.map((header, index) => (
                    <TableHead
                      key={index}
                      className="text-[#4E4E4E] border border-[#EEEEEE] px-4 font-semibold"
                    >
                      {header}
                    </TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {csvData.map((row, rowIndex) => (
                  <TableRow key={rowIndex} className="border border-[#EEEEEE]">
                    <TableCell className="bg-[#F9F9F9] text-center px-4">
                      {rowIndex + 1}
                    </TableCell>
                    {headers.map((header, cellIndex) => (
                      <TableCell
                        key={cellIndex}
                        className="border border-[#EEEEEE] px-5"
                      >
                        {row[header]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TableCell
                    colSpan={headers.length + 1}
                    className="text-right"
                  >
                    Total rows: {csvData.length}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TableModal;
