import React, { useRef, useState } from "react";
import {
  Bar,
  Line,
  Pie,
  Doughnut,
  Radar,
  PolarArea,
  Bubble,
  Scatter,
} from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  RadialLinearScale,
  Filler,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";
import { ResetIcon } from "@radix-ui/react-icons";
import { Button } from "@/components/ui/button";

// Register necessary Chart.js components and the zoom plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  RadialLinearScale,
  Filler,
  Tooltip,
  Legend,
  Title,
  zoomPlugin
);

// Helper function to generate shades of a color
const generateShades = (baseColor: string, count: number) => {
  const shades = [];
  for (let i = 0; i < count; i++) {
    const shade = `${baseColor}${Math.floor(255 - (i * 255) / count)
      .toString(16)
      .padStart(2, "0")}`;
    shades.push(shade);
  }
  return shades;
};

// Randomly pick one base color (red, green, blue, purple) and generate shades
const getRandomBaseColor = () => {
  const baseColors = [
    "#ff0000", // red
    "#00ff00", // green
    "#0000ff", // blue
    "#800080", // purple
  ];
  return baseColors[Math.floor(Math.random() * baseColors.length)];
};

// Helper function to convert date format "YYYY-MM-DD HH:MM:SS" to integer (year)
const convertDateToYear = (labels: string[] | undefined) => {
  if (!labels) return [];
  return labels.map((label) => {
    // Check if the label is in date format "YYYY-MM-DD HH:MM:SS"
    const dateRegex = /^\d{4}-\d{2}-\d{2}/;
    if (dateRegex.test(label)) {
      const year = new Date(label).getFullYear(); // Extract the year
      return year.toString(); // Convert to string
    }
    return label; // Return the original label if not in date format
  });
};

interface ChartData {
  chart_type: string;
  title?: string; // Optional title for the chart
  labels?: string[];
  datasets?: {
    data: number[] | number[][];
  };
  axis_labels?: {
    x_axis?: string;
    y_axis?: string;
  };
}

interface ChartComponentProps {
  charts: ChartData[];
}

const ChartComponent: React.FC<ChartComponentProps> = ({ charts }) => {
  // Create an array of references for each chart
  const chartRefs = useRef<(any | null)[]>([]);
  const [zoomEnabled, setZoomEnabled] = useState<boolean[]>(
    charts.map(() => false)
  );

  // Function to reset zoom for a specific chart
  const handleResetZoom = (index: number) => {
    if (chartRefs.current[index]) {
      chartRefs.current[index].resetZoom(); // Reset zoom for the specific chart
      setZoomEnabled((prev) => {
        const updatedZoomEnabled = [...prev];
        updatedZoomEnabled[index] = false; // Disable zoom until chart is clicked again
        return updatedZoomEnabled;
      });
    }
  };

  // Enable zoom on chart click
  const handleChartClick = (index: number) => {
    setZoomEnabled((prev) => {
      const updatedZoomEnabled = [...prev];
      updatedZoomEnabled[index] = true; // Enable zoom for the clicked chart
      return updatedZoomEnabled;
    });
  };

  const renderChart = (chart: ChartData, index: number) => {
    const baseColor = getRandomBaseColor(); // Get a random base color for each chart
    const shades = generateShades(baseColor, chart.labels?.length || 1); // Generate shades for sections

    // Convert date labels to year integers
    const updatedLabels = convertDateToYear(chart.labels);

    const chartDatasets = [
      {
        data: chart.datasets?.data || [],
        backgroundColor: shades,
        borderColor: shades,
        borderWidth: 1,
      },
    ];

    // Default options for all chart types
    const commonOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false, // Hide the legend
        },
        title: {
          display: !!chart.title, // Display title if it exists
          text: chart.title, // Set the title text
          font: {
            size: 18,
          },
          padding: {
            top: 10,
            bottom: 20,
          },
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: zoomEnabled[index], // Allow zooming with the mouse wheel only when chart is clicked
            },
            pinch: {
              enabled: zoomEnabled[index], // Allow zooming with pinch gestures on touch devices
            },
            mode: "xy" as const, // Enable zooming on both axes
          },
          pan: {
            enabled: zoomEnabled[index],
            mode: "xy" as const, // Enable panning on both axes
          },
        },
      },
      scales:
        chart.chart_type === "pie"
          ? undefined // Disable scales for pie chart (hides x and y axes)
          : {
              x: {
                beginAtZero: true,
                title: {
                  display: chart.axis_labels?.x_axis ? true : false, // Show if x_axis is available
                  text: chart.axis_labels?.x_axis || "", // Set x-axis label if available
                },
                grid: {
                  display: true, // Show grid for other chart types
                },
              },
              y: {
                beginAtZero: true,
                title: {
                  display: chart.axis_labels?.y_axis ? true : false, // Show if y_axis is available
                  text: chart.axis_labels?.y_axis || "", // Set y-axis label if available
                },
                grid: {
                  display: true, // Show grid for other chart types
                },
              },
            },
    };

    switch (chart.chart_type.toLowerCase()) {
      case "bar":
        return (
          <Bar
            onClick={() => handleChartClick(index)} // Enable zoom on click
            ref={(el) => (chartRefs.current[index] = el)} // Attach each chart ref to the chartRefs array
            key={index}
            data={{
              labels: updatedLabels || [], // Use updated labels (converted dates)
              datasets: chartDatasets,
            }}
            options={commonOptions}
          />
        );

      case "line":
        return (
          <Line
            onClick={() => handleChartClick(index)} // Enable zoom on click
            ref={(el) => (chartRefs.current[index] = el)}
            key={index}
            data={{
              labels: updatedLabels || [], // Use updated labels (converted dates)
              datasets: chartDatasets,
            }}
            options={commonOptions}
          />
        );

      case "pie":
        return (
          <Pie
            onClick={() => handleChartClick(index)} // Enable zoom on click
            ref={(el) => (chartRefs.current[index] = el)}
            key={index}
            data={{
              labels: updatedLabels || [], // Use updated labels (converted dates)
              datasets: chartDatasets,
            }}
            options={commonOptions}
          />
        );

      case "doughnut":
        return (
          <Doughnut
            onClick={() => handleChartClick(index)} // Enable zoom on click
            ref={(el) => (chartRefs.current[index] = el)}
            key={index}
            data={{
              labels: updatedLabels || [], // Use updated labels (converted dates)
              datasets: chartDatasets,
            }}
            options={commonOptions}
          />
        );

      case "radar":
        return (
          <Radar
            onClick={() => handleChartClick(index)} // Enable zoom on click
            ref={(el) => (chartRefs.current[index] = el)}
            key={index}
            data={{
              labels: updatedLabels || [], // Use updated labels (converted dates)
              datasets: chartDatasets,
            }}
            options={commonOptions}
          />
        );

      case "polararea":
        return (
          <PolarArea
            onClick={() => handleChartClick(index)} // Enable zoom on click
            ref={(el) => (chartRefs.current[index] = el)}
            key={index}
            data={{
              labels: updatedLabels || [], // Use updated labels (converted dates)
              datasets: chartDatasets,
            }}
            options={commonOptions}
          />
        );

      case "bubble":
        return (
          <Bubble
            onClick={() => handleChartClick(index)} // Enable zoom on click
            ref={(el) => (chartRefs.current[index] = el)}
            key={index}
            data={{
              labels: updatedLabels || [], // Use updated labels (converted dates)
              datasets: chartDatasets,
            }}
            options={commonOptions}
          />
        );

      case "scatter":
        return (
          <Scatter
            onClick={() => handleChartClick(index)} // Enable zoom on click
            ref={(el) => (chartRefs.current[index] = el)}
            key={index}
            data={{
              labels: updatedLabels || [], // Use updated labels (converted dates)
              datasets: chartDatasets,
            }}
            options={commonOptions}
          />
        );

      default:
        return <p key={index}>Unsupported chart type: {chart.chart_type}</p>;
    }
  };

  return (
    <div className="chart-container" style={{ width: "100%" }}>
      {charts.map((chart, index) => (
        <div
          key={index}
          className="chart-item w-[326px] h-96 lg:w-[800px] 2xl:w-[1050px] relative"
        >
          {/* Reset Zoom Button for each chart */}
          <Button
            onClick={() => handleResetZoom(index)}
            className="absolute top-2.5 right-2.5 z-10 p-2 bg-primary/90"
            size={"icon"}
          >
            <ResetIcon />
          </Button>

          {/* Render the specific chart */}
          {renderChart(chart, index)}
        </div>
      ))}
    </div>
  );
};

export default ChartComponent;
