import { useEffect } from "react";
import { cn } from "@/lib/utils";
import { FaRegUser } from "react-icons/fa";
import { Skeleton } from "@/components/ui/skeleton";
import DOMPurify from "dompurify";
import ChartComponent from "./ChartComponent";
import Vantiqa from "@/public/logo-white.png";
import CSVTable from "./csvComponent"; // Import CSVTable
import { useChatFiles } from "@/store/useChatFiles";
import PdfImage from "@/public/pdf.png";
import csvImage from "@/public/csv-file.png";

type Message = {
  content: any;
  context?: any;
  role: string;
};

type ChatCardProps = {
  messageRef: React.RefObject<HTMLDivElement>;
  messages: Message[];
  data: any;
  isLoading: boolean;
  botLoading: boolean;
};

const ChatCard = ({
  messageRef,
  messages,
  data,
  isLoading,
  botLoading,
}: ChatCardProps) => {
  const { csvFiles, pdfFiles } = useChatFiles();

  useEffect(() => {
    if (data) {
      messageRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [data, messageRef]);

  // Filter the charts from the box chart type and leave the rest
  const filterCharts = (charts: any) => {
    return charts.filter((chart: any) => chart.chart_type !== "box");
  };

  const isCSVLink = (content: string) => {
    const csvLinkPattern =
      /^https:\/\/vantiqa-chatbot-app\.s3\.amazonaws\.com\/\d+\/chatdf\/[a-f0-9-]+\.csv$/;
    return csvLinkPattern.test(content);
  };

  const viewSpecialFormats = (message: string) => {
    const sanitized = DOMPurify.sanitize(message);
    return (
      <div
        className="table-container list-container max-h-[1000px]"
        dangerouslySetInnerHTML={{ __html: sanitized }}
      />
    );
  };

  const renderMessage = (message: Message, index: number) => {
    const isChart =
      typeof message.content === "object" && message.content.charts;

    if (isChart) {
      return filterCharts(message.content.charts).map(
        (chart: any, chartIndex: number) => (
          <div
            key={`${index}-${chartIndex}`}
            className={cn(
              "w-full flex items-center gap-x-4",
              message.role === "user"
                ? "self-end flex-row-reverse"
                : "self-start"
            )}
          >
            <div
              className={cn(
                "mt-1 w-10 h-10 rounded-lg flex items-center justify-center",
                message.role === "user"
                  ? "bg-brand-black self-end"
                  : "bg-brand-primary self-start"
              )}
            >
              {message.role === "user" ? (
                <FaRegUser className="text-white" />
              ) : (
                <img src={Vantiqa} alt="" width={20} height={20} />
              )}
            </div>

            <div
              className={cn(
                "p-2 rounded-xl break-words flex items-center gap-x-4 mt-4",
                "max-h-[1000px] overflow-y-auto", // Limit the message height and allow scrolling
                message.role === "user"
                  ? "self-end text-[#404040] bg-[#F2F2F2] max-w-[calc(50%-8px)] "
                  : "max-w-[calc(80%-8px)] self-start bg-[#F9F9FB] border-2 border-[#EEEEEE] text-[#494949] overflow-x-auto"
              )}
            >
              <ChartComponent charts={[chart]} />
            </div>
          </div>
        )
      );
    }

    const isCSV =
      typeof message.content === "string" && isCSVLink(message.content);

    // If it's a CSV, check if CSVTable has valid data, otherwise don't render anything
    if (isCSV) {
      const csvHasData = csvFiles.some((file) => file === message.content); // Use `csvFiles` to check if the file exists and has data

      return (
        <div
          key={index}
          className={cn(
            "w-full flex items-center gap-x-4",
            message.role === "user" ? "self-end flex-row-reverse" : "self-start"
          )}
        >
          <div
            className={cn(
              "mt-1 w-10 h-10 rounded-lg flex items-center justify-center",
              message.role === "user"
                ? "bg-brand-black self-end"
                : "bg-brand-primary self-start"
            )}
          >
            {message.role === "user" ? (
              <FaRegUser className="text-white" />
            ) : (
              <img src={Vantiqa} alt="" width={20} height={20} />
            )}
          </div>

          <div
            className={cn(
              "p-2 rounded-xl break-words flex items-center gap-x-4 mt-4 message",
              "max-h-[1000px] overflow-y-auto",
              message.role === "user"
                ? "self-end text-[#404040] bg-[#F2F2F2] max-w-[calc(50%-8px)] "
                : "max-w-[calc(80%-8px)] self-start bg-[#DBDBDB] text-[#494949] overflow-auto"
            )}
          >
            <CSVTable csvLink={message.content} csvFileName={message.context} />
          </div>
        </div>
      );
    }

    return (
      <div
        key={index}
        className={cn(
          "w-full flex items-center gap-x-4",
          message.role === "user" ? "self-end flex-row-reverse" : "self-start"
        )}
      >
        <div
          className={cn(
            "mt-1 w-10 h-10 rounded-lg flex items-center justify-center",
            message.role === "user"
              ? "bg-brand-black self-end"
              : "bg-brand-primary self-start"
          )}
        >
          {message.role === "user" ? (
            <FaRegUser className="text-white" />
          ) : (
            <img src={Vantiqa} alt="" width={20} height={20} />
          )}
        </div>

        <div
          className={cn(
            "p-2 rounded-xl break-words flex items-center gap-x-4 mt-4 message",
            "max-h-[1000px] overflow-y-auto",
            message.role === "user"
              ? "self-end text-[#404040] bg-[#F2F2F2] max-w-[calc(50%-8px)] "
              : "max-w-[calc(80%-8px)] self-start bg-[#DBDBDB] text-[#494949] overflow-auto"
          )}
        >
          {viewSpecialFormats(message.content)}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full max-h-full md:px-20 flex flex-col py-2 overflow-x-hidden relative">
      {isLoading && (
        <div className="w-full max-h-full flex flex-col">
          {[1, 2].map((_, i) => (
            <div
              key={i}
              className={cn(
                "w-full flex items-center gap-x-4",
                i === 0 ? "self-start" : "self-end flex-row-reverse"
              )}
            >
              <div
                className={cn(
                  "mt-1 w-10 h-10 rounded-lg flex items-center justify-center",
                  i === 0 ? "self-start" : "self-end"
                )}
              >
                <Skeleton className="w-10 h-10 rounded-md ml-1" />
              </div>
              <div
                className={cn(
                  "max-w-[calc(50%-8px)] p-2 rounded-xl break-words flex items-center gap-x-4",
                  i === 0 ? "self-start" : "self-end"
                )}
                style={{ marginTop: "8px" }}
              >
                <Skeleton className="w-[400px] h-[50px] rounded-md" />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className=" w-full flex flex-col  ">
        {csvFiles.map((file, index) => (
          <div
            className={cn(
              "w-full flex items-center gap-x-4",
              "self-end flex-row-reverse"
            )}
          >
            <div
              className={cn(
                "mt-1 w-10 h-10 rounded-lg flex items-center justify-center",

                "bg-brand-black self-end"
              )}
            >
              <FaRegUser className="text-white" />
            </div>

            <div
              className={cn(
                "p-2 rounded-xl break-words flex items-center gap-x-4 mt-4 message",
                "max-h-[1000px] overflow-y-auto", // Limit the message height and allow scrolling
                "self-end text-[#404040] bg-[#F2F2F2] max-w-[calc(50%-8px)] "
              )}
            >
              <div className=" flex items-center gap-x-4">
                <div className=" bg-white/80 w-10 h-10 rounded-md flex items-center justify-center">
                  <img src={csvImage} alt="" width={30} height={30} />
                </div>
                <h5 className="">{file.replace(/[\[\]']/g, "")}.csv</h5>
              </div>
            </div>
          </div>
        ))}
        {pdfFiles.map((file, index) => (
          <div
            className={cn(
              "w-full flex items-center gap-x-4",
              "self-end flex-row-reverse"
            )}
          >
            <div
              className={cn(
                "mt-1 w-10 h-10 rounded-lg flex items-center justify-center",

                "bg-brand-black self-end"
              )}
            >
              <FaRegUser className="text-white" />
            </div>

            <div
              className={cn(
                "p-2 rounded-xl break-words flex items-center gap-x-4 mt-4 message",
                "max-h-[1000px] overflow-y-auto", // Limit the message height and allow scrolling
                "self-end text-[#404040] bg-[#F2F2F2] max-w-[calc(50%-8px)] "
              )}
            >
              <div className=" flex items-center gap-x-4">
                <div className=" bg-white/80 w-10 h-10 rounded-md flex items-center justify-center">
                  <img src={PdfImage} alt="" width={35} height={35} />
                </div>
                <h5 className="">{file}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
      {data && messages.map(renderMessage)}

      {botLoading && (
        <div className="w-full max-h-full flex flex-col">
          <div className="w-full flex items-center gap-x-4 self-start">
            <div className="mt-1 w-10 h-10 rounded-lg flex items-center justify-center self-start">
              <Skeleton className="w-10 h-10 rounded-md ml-1" />
            </div>
            <div className="max-w-[calc(50%-8px)] p-2 rounded-xl break-words flex items-center gap-x-4 self-start">
              <Skeleton className="w-[400px] h-[50px] rounded-md" />
            </div>
          </div>
        </div>
      )}
      <div ref={messageRef} />
    </div>
  );
};

export default ChatCard;
