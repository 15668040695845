import { PaperPlaneIcon } from "@radix-ui/react-icons";
import { useRef, useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import {
  useMutation,
  useQuery,
  RefetchOptions,
  QueryObserverResult,
} from "@tanstack/react-query";
import { SendMessage, GetImage } from "@/data/chat";
import { toast } from "sonner";
import { useAuth } from "@/store/useAuth";
import { jwtDecode } from "jwt-decode";
import { cn } from "@/lib/utils";

type SendMessageInputProps = {
  scrollToBottom: () => void;
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>;
  messages: Message[];
  session: null;
  refetch: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<any, Error>>;
  data: { data: { chat_history: any } } | undefined;
  botLoading: boolean;
  setBotLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refetchChats: any;
};

const SendMessageInput = ({
  scrollToBottom,
  session,
  messages,
  data,
  refetch,
  setMessages,
  botLoading,
  setBotLoading,
  refetchChats,
}: SendMessageInputProps) => {
  const [user_id, setUserId] = useState<number>(0);
  const { token } = useAuth();
  const location = useLocation();
  const { chatId } = useParams<{ chatId: string }>() ?? { chatId: "" };
  const [isEmptyMessage, setIsEmptyMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const messageRef = useRef<HTMLInputElement>(null);

  const { refetch: ImageRefetch } = useQuery({
    queryKey: ["image"],
    queryFn: async () => {
      const response = await GetImage({ chat_id: Number(chatId) });
      setIsEmptyMessage(false);
      return response;
    },
    enabled: !!chatId && isEmptyMessage,
  });

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token) as { id: number };
      setUserId(decoded.id);
    }
  }, [token]);

  const SendMessageMutation = useMutation({
    mutationKey: ["chatMessages", chatId],
    mutationFn: async () => {
      try {
        if (!message) return;

        setMessages([...messages, { role: "user", content: `${message}` }]);

        const response = await SendMessage({
          chat_id: Number(chatId),
          message: message,
          chatHistory: data?.data.chat_history,
        });
        console.log("response message", response);
        if (
          response?.data.response === "" ||
          response?.data.response === "chart"
        ) {
          setIsEmptyMessage(true);
          ImageRefetch();
          setTimeout(() => {
            refetch();
          }, 1000);
        }

        refetch();
        scrollToBottom();

        return response;
      } catch (error) {
        toast.error("Error sending message");
      }
    },
    onSuccess: () => {
      setBotLoading(false);
    },
    onError: () => {
      setBotLoading(false);
      toast.error("Error sending message");
    },
  });

  const handleSendMessage = () => {
    if (!messageRef.current?.value) return;
    if (botLoading) return;

    setBotLoading(true);
    SendMessageMutation.mutate();

    if (messageRef.current) messageRef.current.value = "";
    scrollToBottom();
  };

  return (
    <div
      className={cn(
        "bg-white w-full flex items-center justify-center rounded-xl px-5 border border-border",
        location.pathname === "/chat" &&
          !chatId &&
          "cursor-not-allowed bg-gray-100"
      )}
      style={{
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.05)",
      }}
    >
      <input
        ref={messageRef}
        type="text"
        placeholder="Type a message"
        className="w-full rounded-xl break-words bg-transparent focus:outline-none placeholder:text-[#929292] p-4"
        onKeyDown={(e) => {
          if (e.key === "Enter" && message) {
            handleSendMessage();
          }
        }}
        disabled={botLoading || !chatId}
        onChange={(e) => setMessage(e.target.value)}
      />
      <PaperPlaneIcon
        className={cn(
          `w-6 h-6 text-[#3FBC94] cursor-pointer`,
          location.pathname === "/chat" &&
            !chatId &&
            "text-[#929292] cursor-not-allowed",
          !message || botLoading ? "text-[#929292] cursor-not-allowed" : ""
        )}
        onClick={handleSendMessage}
      />
    </div>
  );
};

export default SendMessageInput;
