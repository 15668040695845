export const categories = {
  textFields: [
    { value: "full_name", name: "Voter Name" },
    { value: "full_name_at_birth", name: "Full Name At Birth" },
    { value: "fathers_given_names", name: "Father's Given Name" },
    { value: "mothers_given_names", name: "Mother's Given Name" },
    { value: "gender", name: "Gender" },
    { value: "ethinicity", name: "Ethnic Group" },
    { value: "religion_group", name: "Religion" },
    { value: "marital_status", name: "Marital Status" },
    { value: "phone_number", name: "Phone Number" },
    { value: "email", name: "Email" },
    { value: "fb_2015_profile_url", name: "Facebook Profile URL" },
    { value: "profile_link", name: "Profile Url" },
    { value: "street_name", name: "Street Name" },
    { value: "community", name: "Community" },
    { value: "corporation", name: "Corporation" },
    {
      value: "parliamentary_electoral_district",
      name: "Parliamentary Electoral District",
    },
    { value: "electoral_district", name: "Municipal Electoral District" },
    {
      value: "pq2relation",
      name: "Relationship To Head Of Household",
    },
    {
      value: "housing_status",
      name: "Housing Status",
    },
    {
      value: "importance_label",
      name: "Importance Score",
    },
    { value: "registered_for_election", name: "Registered For Election" },
    { value: "unc_member_relation", name: "UNC Member Relationship" },
    { value: "eligible_for_voting", name: "Eligible For Voting" },
  ],
  numberFields: [
    { value: "age", name: "Age" },
    { value: "total_childs", name: "Children Status" },
    { value: "polling_division_number", name: "Polling Division" },
    {
      value: "hholdsize",
      name: "Number Of Persons In Household",
    },
    {
      value: "party_support_score",
      name: "Party Support Rating",
    },
    {
      value: "swing_voter_label",
      name: "Swing Voter Rating",
    },
    {
      value: "registration_score",
      name: "Registration Probability Score",
    },
    {
      value: "turn_out_score",
      name: "Turnout Likelihood Score",
    },
  ],
};
export const demoCategories = {
  textFields: [
    { value: "full_name", name: "Voter Name" },

    { value: "gender", name: "Gender" },
    { value: "ethinicity", name: "Ethnic Group" },
    { value: "religion_group", name: "Religion" },
    { value: "phone_number", name: "Phone Number" },

    { value: "street_name", name: "Street Name" },
    { value: "corporation", name: "Corporation" },

    { value: "electoral_district", name: "Municipal Electoral District" },

    {
      value: "importance_label",
      name: "Importance Score",
    },
    { value: "registered_for_election", name: "Registered For Election" },
    { value: "unc_member_relation", name: "UNC Member Relationship" },
    { value: "eligible_for_voting", name: "Eligible For Voting" },
  ],
  numberFields: [
    { value: "age", name: "Age" },
    { value: "polling_division_number", name: "Polling Division" },

    {
      value: "party_support_score",
      name: "Party Support Rating",
    },
    {
      value: "swing_voter_label",
      name: "Swing Voter Rating",
    },
    {
      value: "registration_score",
      name: "Registration Probability Score",
    },
    {
      value: "appartment_number",
      name: "Appartment Number",
    },
    {
      value: "turn_out_score",
      name: "Turnout Likelihood Score",
    },
  ],
};

export const conditions = {
  text: [
    "equals",
    "doesntEqual",
    "contains",
    "doesntContain",
    "startsWith",
    "endswith",
    "doesntstartwith",
    "doesntendwith",
  ],
  number: ["equals", "doesntEqual", "greater", "less", "greatEq", "lessEq"],
};

export const conditionLabels = {
  equals: "Equals",
  doesntEqual: "Doesn't Equal",
  contains: "Contains",
  doesntContain: "Doesn't Contain",
  startsWith: "Starts with",
  endswith: "Ends with",
  doesntstartwith: "Doesn't Start with",
  doesntendwith: "Doesn't End with",
  greater: "Greater than",
  less: "Less than",
  greatEq: "Greater than or equal",
  lessEq: "Less than or equal",
};
