import { useState, useEffect, useRef } from "react";
import { HamburgerMenuIcon, SymbolIcon } from "@radix-ui/react-icons";
import { useOnClickOutside } from "usehooks-ts";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Logo from "@/public/Vantiqa logo.png";
import { Input } from "@/components/ui/input";
import { useControlSideBar } from "@/store/useControlSideBar";
import { Button } from "@/components/ui/button";
import { jwtDecode } from "jwt-decode";
import UserActions from "../header/user-actions";
import SidebarItem from "./item";
import { useUserData } from "@/store/useUserData";
import { useMutation } from "@tanstack/react-query";
import { CreateChat } from "@/data/chat";
import { toast } from "sonner";
import SideMenuDialog from "./popover";
type token = {
  id: number;
  username: string;
};

type SidebarProps = {
  session: null;
  data: any;
  isLoading: boolean;
  refetch: any;
};

type Chat = {
  date_created: Date;
  id: number;
  name: string;
};

const Sidebar = ({ session, data, isLoading, refetch }: SidebarProps) => {
  const { chatId } = useParams<{ chatId: string }>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const navigate = useNavigate();
  const location = useLocation();

  const { token } = useUserData();
  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token) as token;
      setUserId(decoded.id);
    }
  }, [token]);

  const { isOpen, setIsOpen } = useControlSideBar();
  const ref = useRef<HTMLDivElement>(null);

  let chats = data?.data?.chats ?? [];

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const handelClickCreateChat = () => {
    createChat.mutate();
  };

  useEffect(() => {
    if (location.pathname === "/chat" || location.pathname === "/chat/") {
      setIsOpen(true);
      setIsDialogOpen(true);
    } else {
      setIsOpen(false);
      setIsDialogOpen(false);
    }
  }, [location.pathname]);
  const createChat = useMutation({
    mutationKey: ["chats", userId],
    mutationFn: async () => {
      const response = await CreateChat({
        user_id: userId,
        chatName: `Chat ${getRandomNumber(1, 10000).toString()}`,
      });
      console.log("response", response);
      if (response.data.chat) {
        navigate(`/chat/${response.data.chat.id}`);
      }
      return response;
    },
    onSuccess: () => {
      refetch();
      setIsOpen(false);
      console.log("createChat", createChat);
      navigate(`/chat/${createChat.data.data.chat.id}`);
    },
    onError: () => {
      toast.error("Error creating chat");
    },
  });
  function getRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useOnClickOutside(ref, handleClickOutside);

  chats = [...chats].sort(
    (a: Chat, b: Chat) =>
      new Date(b.date_created).getTime() - new Date(a.date_created).getTime()
  );

  if (chatId) {
    const currentChatIndex = chats.findIndex(
      (chat: Chat) => chat.id === Number(chatId)
    );
    if (currentChatIndex !== -1) {
      const currentChat = chats.splice(currentChatIndex, 1)[0];
      chats.unshift(currentChat);
    }
  }

  if (searchQuery) {
    chats = chats.filter((chat: Chat) =>
      chat.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  return (
    <div className="relative">
      <HamburgerMenuIcon
        className="w-6 h-6 text-white cursor-pointer"
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <div
        className={`fixed top-0 left-0 h-full w-[315px] bg-brand-black shadow z-50 transition-transform transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } duration-500`}
        ref={ref}
      >
        <div className="w-full h-full flex flex-col gap-y-9 text-white px-[22px] py-[30px] ">
          <img src={Logo} alt="logo" width={240} height={200} />
          <div className="sm:hidden">
            <UserActions session={session} inSidebar={true} />
          </div>
          <div className="w-full flex flex-col gap-y-2">
            <Button
              variant={"outline"}
              className="rounded-xl text-brand-black gap-x-2"
              onClick={handelClickCreateChat}
              disabled={createChat.isPending}
            >
              Create a new chat
              {createChat.isPending && (
                <SymbolIcon className="w-4 h-4 animate-spin" />
              )}
            </Button>
            <span className="col-span-3 font-bold mt-4">Chat app</span>
            <Input
              placeholder="Search"
              className="rounded-xl placeholder:text-white"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-y-3 overflow-y-auto">
            <span className="font-bold">Previous 30 Days</span>
            <div className="flex flex-col gap-y-3 ">
              {chats.map((chat: Chat) => (
                <SidebarItem
                  chat={chat}
                  chatId={Number(chatId)}
                  key={chat.id}
                  chats={chats}
                  refetch={refetch}
                />
              ))}
            </div>
          </div>
        </div>
        <SideMenuDialog
          createChat={createChat}
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          setSideBarOpen={setIsOpen}
        />
      </div>
    </div>
  );
};

export default Sidebar;
