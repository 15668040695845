import axios from "axios";
import { baseURL } from "@/config/axios";

export const CreateChat = async ({
  user_id,
  chatName,
}: {
  user_id: number;
  chatName: string;
}) => {
  console.log("hello from create chat");
  console.log("user_id", chatName);
  try {
    const response = axios.post(baseURL + "/api/create_chat", {
      name: chatName,
      user_id: user_id,
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const GetChats = async ({ user_id }: { user_id: number }) => {
  try {
    const response = await axios.get(baseURL + `/api/userchats/${user_id}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

type ChatIdParams = {
  chat_id: number | undefined;
};

export const GetChatMessages = async ({ chat_id }: ChatIdParams) => {
  if (!chat_id) return console.error("Chat ID is required");

  try {
    const response = await axios.get(baseURL + `/api/chatmessages/${chat_id}`);

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const SendMessage = async ({
  chat_id,
  message,
  chatHistory,
}: {
  chat_id: number;
  message: string;
  chatHistory: Message[] | undefined;
}) => {
  try {
    const response = await axios.post(baseURL + `/api/chat`, {
      question: message,
      chat_history: chatHistory,
      chat_id: chat_id,
    });
    console.log("response", response);
    return response;
  } catch (error) {
    console.error(error);
  }
};

//  not used anymore
export const UploadFile = async (formData: FormData) => {
  try {
    const response = await axios.post(baseURL + "/api/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
export const RenameChat = async ({
  chat_id,
  chatName,
}: {
  chat_id: number;
  chatName: string;
}) => {
  try {
    const response = await axios.put(baseURL + `/api/rename_chat/${chat_id}`, {
      name: chatName,
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const DeleteChat = async ({ chat_id }: { chat_id: number }) => {
  console.log("chat_id", chat_id);
  try {
    const response = await axios.delete(
      baseURL + `/api/delete_chat/${chat_id}`
    );
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const GetImage = async ({ chat_id }: { chat_id: number }) => {
  try {
    const response = await axios.get(
      baseURL + `/api/get_image?chat_id=${chat_id}`
    );
    console.log("response", response);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
// not used anymore
// export const GetSummary = async ({ chat_id }: { chat_id: number }) => {
//   try {
//     const response = await axios.get(baseURL + `/api/chat_summary/${chat_id}`);
//     return response;
//   } catch (error) {
//     console.error(error);
//   }
// };
