import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ConversationPage from "@/app/(chat)/(routes)/chat/page";
import Chat from "@/app/(chat)/(routes)/chat/[chatId]/page";
import { ToastContainer } from "react-toastify";
import PersonsData from "./pages/SearchandFilters/Persons";
import { MunicipalityProvider } from "./components/Context/MuncipiltyContext";
import PrivateRoute from "./components/Routes/privateRoute";
import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import NavBar from "./components/navbar";
import UserContext from "./components/Context/userContext";
import Users from "./pages/userManagment";
import VoterProfile from "./components/voterprofile";
import LookerReport from "./components/lookerdashboard";
import Login from "./pages/LoginPage/LoginPage";
import OTPForm from "./components/OneTimePassword";
import ContentWrapper from "./components/content-wrapper";
const HomePage = () => {
  const [userRole, setUserRole] = React.useState(null);
  useEffect(() => {
    const token = Cookies.get("ElectionToken");
    if (token) {
      const { role } = jwtDecode(token);
      setUserRole(role);
    }
  }, []);
  return (
    <div className=" w-full h-screen ">
      <ToastContainer />

      <UserContext.Provider value={{ userRole, setUserRole }}>
        <MunicipalityProvider>
          <Router>
            <NavBar />
            <ContentWrapper>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<PersonsData />} />
                  <Route path="/users" element={<Users />} />
                  <Route path="/chat" element={<ConversationPage />} />
                  <Route path="/chat/:chatId" element={<Chat />} />
                  <Route
                    path="/personaldetails/:id"
                    element={<VoterProfile />}
                  />
                  <Route path="/otpCheck" element={<OTPForm />} />
                  <Route path="/dashboard" element={<LookerReport />} />
                </Route>
              </Routes>
            </ContentWrapper>
          </Router>
        </MunicipalityProvider>
      </UserContext.Provider>
    </div>
  );
};

export default HomePage;
