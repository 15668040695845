import { create } from "zustand";

type Chart = {
  charts: any;
  setCharts: (charts: any) => void;
};

export const useCharts = create<Chart>((set) => ({
  charts: null,
  setCharts: (charts) => {
    set({ charts });
  },
}));
