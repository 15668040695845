import axios from "axios";

const baseURL = "https://vantiqa-election-chatdemo.vantiqa.com";
// const baseURL = "http://127.0.0.1:5000";
const api = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

export { api, baseURL };
