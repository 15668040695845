import userpicture from "../../images/user__1_-removebg-preview.png";
import maleuser from "../../images/male-placeholder-image.jpeg";
import femaleuser from "../../images/female-placeholder.jpg";
//import "../styles/profileHeader.css";
import PersonIcon from "@mui/icons-material/Person";
import TransgenderIcon from "@mui/icons-material/Transgender";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { toProperCase } from "../../helperFunctions/functions";
import "../styles/index.css";
import userbackground from "../../images/userbackground.png";
import { getRoleFromToken } from "../../services/searchPageApis/api";
const InformationItem = ({ icon, title, subtitle }) => {
  return (
    <li className="flex items-center mb-4">
      <div className="flex-shrink-0">{icon}</div>
      <div className="ml-3">
        <div className="text-sm font-semibold text-gray-700">{title}</div>
        <div className="text-sm text-gray-600">{subtitle}</div>
      </div>
    </li>
  );
};

const ProfileHeader = ({ data }) => {
  const userProfileImage = data.gender === "FEMALE" ? femaleuser : maleuser;
  let IconComponent;
  if (data.gender) {
    switch (data.gender.toLowerCase()) {
      case "male":
        IconComponent = (
          <MaleIcon className="text-gray-400 bg-gray-100 rounded-full" />
        );
        break;
      case "female":
        IconComponent = (
          <FemaleIcon className="text-gray-400 bg-gray-100 rounded-full" />
        );
        break;
      case "other":
        IconComponent = (
          <TransgenderIcon className="text-gray-400 bg-gray-100 rounded-full" />
        );
        break;
      default:
        IconComponent = (
          <MaleIcon className="text-gray-400 bg-gray-100 rounded-full" />
        );
        break;
    }
  } else {
    // Handle the case when data.gender does not exist
    IconComponent = (
      <MaleIcon className="text-gray-400 bg-gray-100 rounded-full" />
    );
  }

  return (
    <div className="w-full  overflow-hidden shadow-lg h-full relative ">
      {/* Increase the height of the background image container to show more of the image */}
      <img
        src={userbackground}
        alt="Background"
        className="absolute top-0 left-0 w-full h-40 object-cover"
      />

      <div className="flex flex-col items-center relative z-10">
        {/* Increase the size of the user profile picture */}
        <img
          className="rounded-full h-32 w-32 mt-20 border-4 border-white"
          src={userProfileImage}
          alt="User"
        />

        <h3 className="text-lg font-semibold mt-4">
          {toProperCase(data.full_name)}
        </h3>
        <hr
          className="border-t my-3 w-5/6"
          style={{ borderColor: "#000000" }}
        />
      </div>
      <ul className="text-gray-600">
        <li className="text-left">
          <InformationItem
            icon={IconComponent}
            title="Gender"
            subtitle={toProperCase(data.gender)}
          />
        </li>
        <li className="text-left">
          <InformationItem
            icon={
              <EmailOutlinedIcon className="text-gray-400 bg-gray-100 rounded-full" />
            }
            title="Email"
            subtitle={toProperCase(data.email)}
          />
        </li>
        <li className="text-left">
          <InformationItem
            icon={
              <LocationOnIcon className="text-gray-400 bg-gray-100 rounded-full" />
            }
            title="Address"
            subtitle={toProperCase(data.Address, true)}
          />
        </li>
        <li className="text-left">
          <InformationItem
            icon={
              <PhoneInTalkIcon className="text-gray-400 bg-gray-100 rounded-full" />
            }
            title="Phone Number"
            subtitle={toProperCase(data.phone_number)}
          />
        </li>
        {getRoleFromToken() !== "Demo" && (
          <li className="text-left">
            <InformationItem
              icon={
                <CalendarMonthIcon className="text-gray-400 bg-gray-100 rounded-full" />
              }
              title="Date of Birth"
              subtitle={toProperCase(data.date_of_birth)}
            />
          </li>
        )}
      </ul>
    </div>
  );
};
export default ProfileHeader;
