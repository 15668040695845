import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { SymbolIcon } from "@radix-ui/react-icons";

type SideMenuDialogProps = {
  createChat: any;
  isDialogOpen: boolean;
  setSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const SideMenuDialog = ({
  createChat,
  isDialogOpen,
  setSideBarOpen,
  setIsDialogOpen,
}: SideMenuDialogProps) => {
  const handelCancel = () => {
    setIsDialogOpen(false);
    setSideBarOpen(true);
  };
  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent className=" text-left">
        <DialogHeader>
          <DialogTitle className=" text-left">Create a new Chat</DialogTitle>
          <DialogDescription className=" text-left">
            You have to create a new chat or to select a chat from the side menu
            to start messaging
          </DialogDescription>
        </DialogHeader>

        <DialogFooter>
          <Button
            variant="outline"
            onClick={handelCancel}
            disabled={createChat.isPending}
          >
            Cancel
          </Button>
          <Button
            className=" bg-brand-black gap-x-2"
            onClick={() => createChat.mutate()}
            disabled={createChat.isPending}
          >
            Create a new Chat
            {createChat.isPending && (
              <SymbolIcon className="w-4 h-4 animate-spin" />
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SideMenuDialog;
