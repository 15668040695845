import { create } from "zustand";
type useFetchChats = {
  session: any;
  data: any;
  isLoading: boolean;
  refetch: any;
  setSession: (session: any) => void;
  setData: (data: any) => void;
  setIsLoading: (isLoading: boolean) => void;
  setRefetch: (refetch: any) => void;
};

export const useFetchChats = create<useFetchChats>((set) => ({
  session: null,
  data: null,
  isLoading: false,
  setSession: (session) => set({ session }),
  refetch: () => {},
  setData: (data) => set({ data }),
  setIsLoading: (isLoading) => set({ isLoading }),
  setRefetch: (refetch) => set({ refetch }),
}));
